@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
:root {
  --priceless-very-dark-blue: #1a202c;
  --priceless-dark-blue: #26334d;
  --priceless-blue: #2a4365;
  --priceless-pale-blue: #e2e8f0;
  --blue: var(--priceless-blue);
  --red: #be001a;
  --lightgrey: #ecebeb;
  --grey: #555;
  --accent-color: var(--red);
  --custom-header-font: Poppins;
  --custom-main-font: Karla;
  --breadcrumbs-background: var(--lightgrey);
  --link-foreground-color: var(--priceless-blue);
  --theme-low-contrast-foreground-color: var(--priceless-blue);
  --theme-foreground-color: var(--priceless-dark-blue);
}
