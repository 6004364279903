// Intentionally empty
// Used for customizations in other add-ons
@import 'variables';
//General
html {
  scroll-behavior: smooth;
}
#page-document .blocks-group-wrapper > h2,
#page-document .blocks-group-wrapper > h3,
#page-document .blocks-group-wrapper > h4,
#page-document .blocks-group-wrapper > h5,
#page-document .blocks-group-wrapper > h6,
#page-document .blocks-group-wrapper > ul,
#page-document .blocks-group-wrapper > ol,
#page-document .blocks-group-wrapper > li,
#page-document .blocks-group-wrapper > p,
#page-document .blocks-group-wrapper > span,
#page-document .blocks-group-wrapper > blockquote,
#page-document .blocks-group-wrapper > .block.image.align.left.medium,
#page-document .blocks-group-wrapper > .block.image.align.left.small,
#page-document .blocks-group-wrapper > .block.image.align.right.medium,
#page-document .blocks-group-wrapper > .block.video.align.left,
#page-document .blocks-group-wrapper > .block.video.align.right,
#page-document .blocks-group-wrapper > .block.maps.align.left,
#page-document .blocks-group-wrapper > .block.maps.align.right,
#page-document .blocks-group-wrapper > .block.image.align.right.small,
#page-document .blocks-group-wrapper > .block.video.align.center .video-inner,
#page-document .blocks-group-wrapper > .block.maps.align.center .maps-inner,
#page-document .blocks-group-wrapper .download-event,
#page-document .blocks-group-wrapper .block.separator.has--align--left .line,
#page-document .blocks-group-wrapper .block.separator.has--align--center .line,
#page-document .blocks-group-wrapper > pre,
#page-document .blocks-group-wrapper > .block.code,
.columns-view {
  max-width: var(--default-container-width) !important;
}
a {
  color: var(--priceless-blue);
  text-decoration: none !important;
}
//Navigation
.header .logo-wrapper {
  margin: 20px 0;
  display: flex;
  flex-grow: unset;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header .logo-wrapper > * {
  flex: 0 0 40% !important;
}
.headerlogos {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.headerlogos a {
  padding: 0 14px;
}
.headerlogos a img {
  max-width: 100%;
  height: auto;
}
.nav-wrap-colour {
  background-color: var(--priceless-blue);
}
.header-wrapper .header .logo-nav-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
}
.header-wrapper .header .logo-nav-wrapper .navigation {
}
.navigation .ui.secondary.pointing.menu {
  margin-left: 0;
}
.mobile-nav {
  z-index: 1000;
}
#navigation .hamburger-wrapper {
  display: none !important;
}
.navigation {
  height: auto;
}
.navigation ul.desktop-menu > li > button,
.navigation ul.desktop-menu > li > a {
  font-family: var(--custom-main-font, sans-serif) !important;
  font-weight: 300 !important;
}
.ui.secondary.pointing.menu .active.item {
  border-color: var(--red);
  color: rgba(255, 255, 255, 1) !important;
}
.ui.secondary.pointing.menu .active.item:hover,
.ui.secondary.pointing.menu .item:hover {
  border-color: var(--red);
  color: rgba(255, 255, 255, 1) !important;
}
.ui.secondary.pointing.menu .item {
  text-transform: initial !important;
  color: rgba(255, 255, 255, 0.55);
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
//Slider
.block.slider .teaser-item .teaser-item-title button {
  color: #fff;
  border: 1px solid #fff;
}
.block.slider .teaser-item .teaser-item-title button:hover {
  background: #fff;
  color: var(--green);
}
.blocks-group-wrapper:has(.block.slider) {
  padding-bottom: 0;
}
.block.slider {
  margin-bottom: 0 !important;
}
.block.slider .teaser-item .teaser-item-title {
  background: var(--priceless-dark-blue);
}
.block.slider .teaser-item .teaser-item-title .title h2 {
  font-weight: 600;
}
//Slide Carousel
.slick-slide p {
  color: #000;
  font-size: 16px;
  line-height: initial;
}
//Columns Teasers
body .block.gridBlock .block.teaser {
  border-radius: 12px !important;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0px 3px 10px -4px #000;
}
body .block.gridBlock .block.teaser .teaser-item {
  background: var(--priceless-blue);
}
body .block.gridBlock .column:nth-child(even) .block.teaser .teaser-item {
  background: var(--priceless-dark-blue);
}
body .block.teaser h2,
body .block.teaser p,
body .block.teaser .headline {
  color: #fff;
  text-align: center;
}
body .block.teaser p {
  font-size: 16px;
  line-height: initial;
}
body .block.gridBlock .block.teaser p:last-child {
  margin-bottom: 0;
}
.block.gridBlock .block.teaser .teaser-item .image-wrapper,
#page-edit
  .block-editor-gridBlock
  .block.gridBlock
  .block.teaser
  .teaser-item
  .image-wrapper,
#page-add
  .block-editor-gridBlock
  .block.gridBlock
  .block.teaser
  .teaser-item
  .image-wrapper {
  margin-bottom: 20px;
}
.blocks-group-wrapper:has(.block.gridBlock) {
  padding-top: 0;
  margin-top: -40px;
  background-color: transparent !important;
  padding-bottom: 0;
}
.default .block.gridBlock {
  background: transparent !important;
  position: relative;
}
//Footer
#footer {
  background: var(--priceless-very-dark-blue) !important;
}
#footer-main {
  font-size: 16px;
}
#footer-main h4 {
  margin-bottom: 8px;
}
#footer-main a {
  font-size: 16px;
  color: #fff;
}
#footer-main .item {
  margin-bottom: 5px;
}
.below-footer {
  font-size: 14px;
  margin: 10px 0;
  color: #ccc;
}
.below-footer a {
  font-size: 14px;
  color: #ccc;
}
//Breadcrumbs
#main .breadcrumbs {
  padding: 10px 0;
}
body #main > .breadcrumbs a.section,
#main .breadcrumbs .breadcrumb .divider::before {
  color: #000 !important;
}
body #main > .breadcrumbs a.home svg {
  fill: #000 !important;
}
#main .breadcrumbs .breadcrumb .section.active {
  display: none !important;
}
//Accordion
body .accordion-block .ui.styled.accordion,
body .block-editor-accordion .ui.styled.accordion {
  border-top: 0;
}
.accordion-block .ui.styled.accordion:last-child,
.block-editor-accordion .ui.styled.accordion:last-child {
  border-bottom: 0;
}
body .accordion-block .ui.styled.accordion .title.accordion-title,
body .block-editor-accordion .ui.styled.accordion .title.accordion-title {
  background: var(--priceless-blue);
  padding: 15px;
  border-top: 1px solid var(--priceless-pale-blue);
  border-bottom: 1px solid var(--priceless-dark-blue);
}
body .accordion-block .ui.styled.accordion .title.accordion-title > span,
body .block-editor-accordion .ui.styled.accordion .title.accordion-title > span,
body .accordion-block .ui.styled.accordion .title.accordion-title.active > span,
body
  .block-editor-accordion
  .ui.styled.accordion
  .title.accordion-title.active
  > span {
  color: #fff;
}
body .accordion-block .ui.styled.accordion .title.accordion-title > svg,
body .block-editor-accordion .ui.styled.accordion .title.accordion-title > svg,
body .accordion-block .ui.styled.accordion .title.accordion-title.active > svg,
body
  .block-editor-accordion
  .ui.styled.accordion
  .title.accordion-title.active
  > svg {
  fill: #fff !important;
}
body .accordion-block .ui.styled.accordion .content {
  margin-top: 30px;
}
.ui.styled.accordion, .ui.styled.accordion .accordion {
  background: transparent;
}
//Search block
#page-document .block.search .listing-item h2, #page-document .block.search .listing-item h3, #page-add .block.search .listing-item h2, #page-add .block.search .listing-item h3, #page-edit .block.search .listing-item h2, #page-edit .block.search .listing-item h3 {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}
.block.search .listing-body p.authors {
  margin-bottom: 5px;
}
.block.search .listing-body p.authors, .block.search .listing-body p.effective-date {
  font-size: 16px
}
//Search icon
.header-wrapper .header .search-wrapper .search button svg path {
  fill: #fff !important;
}
.header-wrapper .header .search-wrapper .search button:hover {
  background-color: var(--red);
}
//Home
.block.carousel .grid-teaser-item h3 {
  display: none;
}
.block.carousel .grid-image-wrapper img {
  aspect-ratio: initial;
  position: initial;
}
.block.carousel .grid-image-wrapper {
  padding-top: 0;
}
//General
#page-document .blocks-group-wrapper.default a {
  --theme-foreground-color: var(--priceless-dark-blue) !important;
  --theme-low-contrast-foreground-color: var(--priceless-blue) !important;
}
#page-document .blocks-group-wrapper.darkblue,
#page-document .blocks-group-wrapper.darkblue a,
#page-document .blocks-group-wrapper.darkblue li {
  color: var(--theme-foreground-color) !important;
}
.siteroot .documentFirstHeading {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h1.documentFirstHeading {
  font-family: var(--custom-header-font, sans-serif) !important;
  font-weight: 400;
}
h1.documentFirstHeading {
  margin-bottom: 40px;
}
.documentDescription {
  max-width: var(--default-container-width) !important;
}
.contenttype-news-item #page-document .dates {
  margin-bottom: 0;
}
.contenttype-news-item h1.documentFirstHeading {
  padding-top: 10px;
}
//Detailed listing
.detailed.listing-item {
  display: flex;
}
.detailed.listing-item a {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: row;
}
/* Ensure listing-body appears first and image second on desktop */
.detailed.listing-item a .listing-body {
  flex: 1; /* Takes remaining space */
  order: -1; /* Moves it before the image */
  width: auto;
  margin-right: 0;
}
.detailed.listing-item a img {
  flex: 0 0 15%; /* 15% width */
  margin-left: 20px;
  height: auto; /* Maintain aspect ratio */
}
/* If no image exists, a takes full width */
.detailed.listing-item a:not(:has(img)) {
  flex: 100%;
}
.detailed.listing-item a.external::after {
  display: none !important;
}
//Gallery
.section-gallery .listing-item p {
  display: none;
}
.section-gallery #page-document .block.search .searchBlock-container .stackable.grid.searchBlock-facets .row:first-child {
  display: none;
}
@media only screen and (min-width: 940px) {
  .navigation .ui.secondary.pointing.menu .item,
  .tools .ui.secondary.pointing.menu .item {
    margin-right: 1.4em;
  }
  //Search block
  /*Search bits*/
  #page-document .block.search .searchBlock-container .stackable.grid.searchBlock-facets .row:first-child {
    flex-grow: 1;
    width: 30% !important;
    padding-left: 20px !important;
  }
  #page-document .block.search .search-input-resultscount-sort, #page-add .block.search .search-input-resultscount-sort, #page-edit .block.search .search-input-resultscount-sort {
    flex-direction: column;
    margin-bottom: 20px;
  }
  #page-document .block.search .search-wrapper, #page-add .block.search .search-wrapper, #page-edit .block.search .search-wrapper {
    width: 100%;
  }
  #page-document .block.search .search-details, #page-add .block.search .search-details, #page-edit .block.search .search-details {
    padding: 8px 0 0;
  }
  #page-document .block.search .search-details .number, #page-add .block.search .search-details .number, #page-edit .block.search .search-details .number {
    font-size: initial;
  }
  #page-document .block.search .facets, #page-add .block.search .facets, #page-edit .block.search .facets {
    margin-top: 0;
  }
  #page-document .block.search .facets .column, #page-document .block.search .listing.message .column, #page-add .block.search .facets .column, #page-add .block.search .listing.message .column, #page-edit .block.search .facets .column, #page-edit .block.search .listing.message .column {
    width: 100% !important;
    padding: 0;
    margin: 0 0 20px;
  }
  /*Listing bits*/
  #page-document .block.search .searchBlock-container .stackable.grid.searchBlock-facets .row:last-child {
    flex-grow: 2;
    width: 70% !important;
    order: -1;
  }
  .detailed.listing-item a img {
    flex: 0 0 25%;
  }
  .listing-item img {
    width: 25%;
  }
}
@media only screen and (max-width: 940px) {
  .header .logo-wrapper .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  .header .logo-wrapper > * {
    flex: 1 1 100% !important;
  }
  //Detailed listing
  .detailed.listing-item a {
    flex-direction: column;
  }
  .detailed.listing-item a .listing-body {
    order: 2;
  }
  .detailed.listing-item a img {
    width: 100%; /* Full width */
    margin: 0 0 20px 0; /* No left margin, 20px bottom */
  }
}
